<template>
    <div class="home">
        <div class="top flex">
            <img class="logo" @click="goHome('')" src="@/assets/home2/logo@2x.png" alt="">
            <div class="flex">
                <div class="nav" @click="goHome('#about')">关于赛事</div>
                <div class="nav" @click="goHome('#format')">赛事赛制</div>
                <div class="nav"  @click="goHome('#review')">评审介绍</div>
                <div class="nav" @click="goSign">赛事报名</div>
            </div>
        </div>
        <!-- <div class="crumbs">首页 > 赛事赛道 > <span style="color: #45B79C">赛道三</span></div> -->
        <div class="crumbs"><span @click="$router.push('/saidao1')">赛道一</span> | <span  @click="$router.push('/saidao2')">赛道二</span> | <span style="color: #45B79C">赛道三</span> | <span @click="$router.push('/saidao4')">赛道四</span> | <span @click="$router.push('/saidao5')">赛道五</span></div>

        <div class="banner">
            <div class="title">赛道三</div>
            <div class="desc">虞山碧螺春包装设计</div>
        </div>
        <div class="content flex-vc" >
            <div class="left">
                <div class="text1">戏作小诗君一笑，从来佳茗似佳人</div>
                <div class="text2">虞山碧螺春是常熟人的骄傲，十里青山半入城，茶园掩映在山林间。<br> 鲜嫩的芽叶正是在这样的山林树木下破势而出，这就是常熟绿茶飘香江南的天赋所在。每年的春分前后，正是虞山碧螺春开采的时节，大概到谷雨结束，春分到清明采摘制作的明前茶，最为名贵。经过杀青、揉捻、做形、提香之后，一杯花香扑鼻的碧螺春就这么来了，一口下去，春光艳潋，舌根留香。</div>
            </div>
            <div class="right">
                <div class="text1">参赛者根据以下要求进行设计</div>
                <div class="text3">
①从开启体验层面（气味/视觉/外观/包装手感等）入手，要求袋泡茶形式，便于携带，结合年轻群体茶叶消费场景，以及消费者心理、行为习惯，洞察消费者需求，在便于使用的基础上，打造全新的消费体验。 <br>②请为作品配上必要的文字说明，包括：使用场景、包装方式及包装尺寸规格等基本信息，作品创作思路或设计说明简述等作品，要求视觉表达创新，材质搭配精妙，细节品质突出。 <br>③作品要考虑其实际生产材质和造型等因素上的可实现性，符合当前消费者的需求和消费水平，并具有实用价值。应具备批量生产及市场推广价值，便于携带 <br>④作品鼓励使用生态环保材料及运用环保理念进行创新设计 <br>⑤内容包含但不限于茶罐、内袋、包装盒、礼盒、配件等 <br>⑥请将设计图、效果图、介绍文字等整合后以方案形式进行呈现，文件大小不大于30M，文件格式为PDF格式
                </div>
            </div>
        </div>
        <!-- footer -->
        <div id="sign" class="footer">
        <img class="logobottom" src="@/assets/home1/logobottom@2x.png" alt="">
        <div class="text-wrap flex-vcbetween">
            <div>
            <div><span>指导单位</span>中共常熟市委宣传部</div>
            <div><span>主办单位</span>常熟文旅发展有限责任公司</div>
            <div><span>承办单位</span>吉捷文化传媒（上海）有限公司</div>
            </div>
            <div class="flex-vc">
            <div><span>官方热线:</span></div>
            <div>
                <div>邮箱: 962991363@qq.com</div>
                <div>微信: 13817518262</div>
            </div>
            </div>
        </div>
        <div class="text-bottom flex-between">
            <div class="fs13"><img class="c" src="@/assets/home1/C@2x.png" alt="">2021 <span style="font-weight: bold">changshustory.</span> ALL Right Reserved <a style="color:#666" href="https://beian.miit.gov.cn/">沪ICP备12038716号-10</a></div>
            <div class="fs15"><span>关于赛事</span>|<span>赛事赛制</span>|<span style="margin-right:0">评审介绍</span></div>
        </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    methods: {
        goSign() {
            this.$router.push('/sign')
        },
        goHome(id){
            this.$router.push({name:'Home',query: {id: id}})
        },
    }
}
</script>
<style lang="less" scoped>
.flex {
  display: flex;
  align-items: center;
}
.flex-vc {
  display: flex;
  justify-content: center;
}
.flex-vcbetween {
  display: flex;
  // align-items: center;
  justify-content: space-between;
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home {
  margin: 0 auto;
  width: 1920px;
  .top {
    background: #FFFFFF;
    height: 134px;
    border-bottom: 1px solid #EBEBEB;
    .logo {
      width: 200px;
      margin-left: 360px;
      margin-right: 328.5px;
      cursor: pointer;
    }
    .nav:hover {
      color:#45B79C;
      font-weight: bold;
      cursor: pointer
    }
    .nav {
      width: 175px;
      font-size: 20px;
      position: relative;
      a {
        text-decoration: none;
        color: #333333;
      }
      span {
        position: absolute;
        left: 49.5px;
        top: 15px;
        display: block;
        width: 76px;
        height: 10px;
        background: #AAAAAA;
        z-index: -1;
      }
    }
  }
    .crumbs {
        width: 1200px;
        line-height: 76px;
        font-size: 20px;
        color: #231815;
        text-align: left;
        margin: 0 auto;
        span {
          cursor: pointer;
        }
    }
    .banner {
        width: 1200px;
        height: 126px;
        background: url('~@/assets/saidao/bg@2x.png');
        background-size: 100% 100%;
        margin: 0 auto;
        color: #FFFFFF;
        padding-top: 20px;
        .title {
            font-size: 44px;
            font-weight: bold;
        }
        .desc {
            margin-top: 10px;
            font-size: 24px;
        }

    }
    .content {
        height: 554px;
        width: 1200px;
        margin: 0 auto;
        text-align: left;
        line-height: 36px;
        .left {
            width: 600px;
        }
        .right {
            width: 540px;
            padding-left: 60px;
        }
        .text1 {
            font-size: 20px;
            color: #231815;
            font-weight: bold;
            margin-top: 32px;
        }
        .text2 {
            margin-top: 14px;
            font-size: 18px;
            color: #231815;
            width: 546px;
        }
        .text3 {
            width: 506px;
            height: 406px;
            border: 1px solid #E5E5E5;
            overflow-y: scroll;
            margin-top: 14px;
            padding: 17px;
            font-size: 18px;
            color: #231815;
        }
    }
      .footer {
    width: 1920px;
    height: 170px;
    background: #C1C1C1;
    position: relative;
    .logobottom {
      position: absolute;
      top: 38px;
      left: 860px;
      width: 200px;
    }
    .text-wrap {
      width: 1170px;
      margin: 0 auto ;
      color: #666666;
      font-size: 13px;
      line-height: 32px;
      text-align: left;
      padding-top: 30px;
      span {
        font-weight: bold;
        font-size: 15px;
        // color: #FFFFFF;
        margin-right: 16px;
        display: inline-block;
      }
    }
    .text-bottom {
      width: 1170px;
      margin: 0 auto;
      line-height: 32px;
      .fs13 {
        font-size: 13px;
        color: #666;
        img {
          width: 10px;
        }
      }
      .fs15 {
        font-size: 15px;
        color: #ABACB1;
        span {
          color: #666666;
          margin: 0 26px;
          display: inline-block;
        }
      }
    }
  }
}
</style>


